<template>
  <div>
    <my-header></my-header>
    <div class="box-card-box">
      <el-card class="box-card">
        <div class="title">白条订单</div>
      </el-card>

      <!-- 查看白条订单 -->
      <el-card class="box-card-1" v-if="state == 1">
        <div v-for="item in listCompany.list" :key="item.id">
          <div v-if="flag == false && flag2 == false" class="pay-box-box">
            <div class="pay_company_box">
              <div class="pay_company_box_img">
                <img :src="item.companyLogo" alt="" />
              </div>
              <div class="pay_company_box_title">
                <span>
                  {{ item.companyName }}
                </span>
              </div>
              <div class="pay_company_box_button">
                <el-Button
                  @click="goCompany(item.id)"
                  class="pay_company_box_button1"
                  type="primary"
                  >进店看看</el-Button
                >
                <el-Button
                  @click="getCreditBillOrder(item.id)"
                  class="pay_company_box_button1"
                  type="primary"
                  >查看白条订单</el-Button
                >
                <el-Button
                  @click="getCreditBillPay(item.id)"
                  class="pay_company_box_button2"
                  type="primary"
                  >查询白条结算单</el-Button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="flag == true">
          <div class="pay_company_list_fanhui">
            <el-button @click="goback" type="primary">返回</el-button>
            <el-date-picker
              v-model="value2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-button @click="closeByBeginAndOverTime" type="primary"
              >生成白条结算单</el-button
            >
          </div>
          <div class="pay_company_list">
            <table>
              <tr>
                <th>订单详情</th>
                <th>商品</th>
                <th>数量</th>
                <th>单价</th>
                <th>总价</th>
                <th>生成时间</th>
                <th>订单状态</th>
                <th>白条状态</th>
              </tr>
            </table>

            <table v-for="it in list.list" :key="it.id" border="1px">
              <tbody v-if="it.sendBack != null">
                <tr v-for="item in it.orderItemVO" :key="item.id">
                  <td class="pay_company_list_img">
                    <img :src="item.productImg" alt="" />
                  </td>
                  <td>{{ item.productName }}</td>
                  <td>{{ item.productNum }}</td>
                  <td>{{ item.unitPrice }}</td>
                  <td>{{ item.totalPrice }}</td>
                  <td>{{ it.createTime }}</td>
                  <td>
                    {{
                      item.state == 10
                        ? "未支付"
                        : item.state == 20
                        ? "已支付"
                        : item.state == 40
                        ? "已发货"
                        : item.state == 50
                        ? "已完成"
                        : "状态错误"
                    }}
                  </td>
                  <td>
                    {{
                      it.sendBack == 1
                        ? "未结算"
                        : it.sendBack == 2
                        ? "结算中"
                        : it.sendBack == 3
                        ? "已结算"
                        : "状态错误"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="flag2 == true">
          <div class="pay_company_list_fanhui">
            <el-button @click="goback2" type="primary">返回</el-button>
          </div>
          <div class="pay_company_list pay_company_list2">
            <table>
              <tr>
                <th>订单id</th>
                <th>总价</th>
                <th>生成时间</th>
                <th>订单状态</th>
                <th>操作</th>
              </tr>
            </table>
            <template v-if="list2.list!=''">
            <table v-for="item in list2.list" :key="item.id" border="1px">
              <tbody>
                <tr>
                  <td>{{ item.id }}</td>
                  <td>{{ item.total }}</td>
                  <td>{{ item.createTime }}</td>
                  <td>
                    白条{{
                      item.state == 1
                        ? "未付款"
                        : item.state == 2
                        ? "已付款"
                        : "状态错误"
                    }}
                  </td>
                  <td v-if="item.state==1">
                    <el-button @click="goPayCreditBill(item.id)" type="primary">结算</el-button>
                  </td>
                  <td v-else>
                    <el-button disabled @click="goPayCreditBill(item.id)" type="primary">结算</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
            </template>
            <template v-else>
              <div>
                暂无白条结算单
              </div>
            </template>
          </div>
        </div>
      </el-card>

      <!-- 查看白条结算单 -->
      <el-card class="box-card-1" v-if="state == 2">
        <div class="pay-box-box">
          <el-table :data="list" style="width: 100%">
            <el-table-column prop="productName" label="商品"></el-table-column>
            <el-table-column prop="companyName" label="店铺"></el-table-column>
            <el-table-column prop="productNum" label="数量"></el-table-column>
            <el-table-column prop="unitPrice" label="单价"></el-table-column>
            <el-table-column prop="totalPrice" label="总价"></el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 支付成功页面 -->
      <el-card class="box-card-2" v-if="state == 3">
        <div class="box-card-2-box">
          <span class="el-icon-circle-check"></span>
          <span>您已付款成功！</span>
          <div>
            <span>现在您可以:</span>
          </div>
          <div>
            <el-button
              type="success"
              @click="$router.push('/purchaseOrderList')"
              >查看订单</el-button
            >
            <el-button plain @click="$router.push('/index')"
              >返回首页</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import myHeader from "../components/my-header.vue";
export default {
  components: { myHeader },
  data() {
    return {
      state: 1,
      listCompany: [],
      companyId: "",
      list: [],
      list2: [],
      userInfo: JSON.parse(sessionStorage.getItem("userinfo")),
      // 控制白条显示
      flag: false,
      flag2: false,

      //日期选择控件
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
    };
  },
  methods: {
    // 控制白条卡关
    goback() {
      if (this.flag == true) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    goback2() {
      if (this.flag2 == true) {
        this.flag2 = false;
      } else {
        this.flag2 = true;
      }
      console.log(this.flag2);
    },
    // 查询用户白条订单公司
    async getCreditBill() {
      const res = await this.$http({
        method: "post",
        url: "front/creditBill/selectCreditCompany",
      });
      console.log(res);
      this.listCompany = res.data.data;
    },
    // 查询对应公司下白条订单
    async getCreditBillOrder(companyId) {
      this.companyId = companyId;
      const res = await this.$http({
        method: "post",
        url: "front/order/findCreditOrder",
        data: {
          userId: this.userInfo.id,
          companyId: companyId,
        },
      });
      this.goback();
      this.list = res.data.data;
      console.log(this.list, "1111111111111111111");
    },
    // 查询对应公司白条结算单
    async getCreditBillPay(companyid) {
      const res = await this.$http({
        method: "get",
        url: `front/creditBill/selectAllCredit?companyId=${companyid}&userId=${this.userInfo.id}`,
      });
      console.log(res, "22222222222");
      this.goback2();
      this.list2 = res.data.data;
      if(this.list2.list==''){
        this.$message({
        message: "暂无白条结算单",
        type: "warning",
      });
      }
      console.log(this.list2, "22222222222222222222");
    },
    // 按时间生成白条结算单
    async closeByBeginAndOverTime() {
      if (this.value2 == "") {
        this.$message({
        message: "请选择订单时间段",
        type: "warning",
      });
        return;
      }

      let beginTimes = this.value2[0];
      let overTimes = this.value2[1];

      let onYear = beginTimes.getFullYear();
      let onMonth = beginTimes.getMonth() + 1;
      let onDay = beginTimes.getDate();

      let overYear = overTimes.getFullYear();
      let overMonth = overTimes.getMonth() + 1;
      let overDay = overTimes.getDate();

      let beginTime = onYear + "-" + onMonth + "-" + onDay;
      let overTime = overYear + "-" + overMonth + "-" + overDay;

      // console.log(beginTime,'开始时间')
      // console.log(overTime,'结束时间')

      const res = await this.$http({
        method: "post",
        url: "front/creditBill/closeByBeginAndOverTime",
        data: {
          companyId: this.companyId,
          beginTime: beginTime,
          overTime: overTime,
        },
      });
      console.log(res)
      // 请查看是否有白条订单，且订单是否已完成！
      if(res.data.status==200){
        if(res.data.data=="请查看是否有白条订单，且订单是否已完成！"){
          this.$message({
          message: "暂无可结算白条订单",
          type: 'warning'
        });
        }else{
          this.$message({
          message: "操作成功",
          type: 'success'
        });
        }
      }
    },
    // 去支付白条结算单
    goPayCreditBill(id){

    },
    // 跳转相应公司首页
    goCompany() {},
  },

  created() {
    this.getCreditBill();
    // console.log(JSON.parse(sessionStorage.getItem("userinfo")))
  },
};
</script>

<style lang="scss">
.box-card-box {
  margin: 0 auto;
  margin-top: 160px;
  width: 1200px;
}
.box-card {
  margin-top: 160px;
  width: 100%;
}
.box-card > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-steps {
  width: 500px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #555;
  line-height: 50px;
}
.box-card-1-header {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background: #f0f9eb;
}
.box-card-1-header-top {
  padding: 5px;
}
.el-icon-success {
  color: #67c23a;
  font-size: 20px;
}
.box-card-1-header-text1 span {
  color: #67c23a;
  padding: 0px 7px;
  font-size: 18px;
}
.box-card-1-header-text2 span:nth-child(1) {
  color: #333;
  margin-left: 15px;
}
.box-card-1-header-text2 span:nth-child(2) {
  color: #e10d0b;
}
.box-card-1-header-text3 span:nth-child(1) {
  color: #333;
  margin-left: 15px;
}
.box-card-1-header-text3 span:nth-child(2) {
  color: #e10d0b;
  font-weight: 600;
}
.box-card-1-header-text3 span:nth-child(3) {
  margin-left: 5px;
}
.pay-box-box {
  width: 100%;
}
.box-card-2-box {
  margin: 50px auto;
  width: 60%;
  text-align: center;
}
.box-card-2-box > span:nth-child(1) {
  color: #67c23a;
  font-size: 60px;
}
.box-card-2-box > span:nth-child(2) {
  color: #67c23a;
  font-size: 30px;
  padding: 5px;
}
.box-card-2-box > div:nth-child(3) span:nth-child(1) {
  display: inline-block;
  color: #666;
  margin: 10px;
}
.el-button--success.is-plain {
  color: red;
}
/* 店铺样式 */
.pay_company_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pay_company_box_img {
    img {
      width: 180px;
      height: 100x;
      border-radius: 10px;
    }
  }
}
// 商品列表样式
.pay_company_list {
  table {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
    td {
      width: 144px;
      vertical-align: middle;
    }
    th {
      width: 144px;
      vertical-align: middle;
    }
  }
  .pay_company_list_img {
    img {
      width: 90px;
      height: 50px;
      border-radius: 10px;
    }
  }
}
.pay_company_list2 {
  table {
    td {
      width: 180px;
      vertical-align: middle;
      &:nth-child(1){
        width: 310px;
      }
    }
    th {
      width: 180px;
      vertical-align: middle;
      &:nth-child(1){
        width: 310px;
      }
    }
  }
}
.pay_company_list_fanhui {
  text-align: right;
  margin-bottom: 20px;
  button,div{
    margin-right: 30px;
  }
}
</style>
