<template>
  <div>
    <my-header></my-header>
    <div class="box-card-box">
      <el-card class="box-card">
        <div class="title">收银台</div>
        <el-steps
          class="el-steps"
          :space="200"
          :active="parseInt(state / 10)"
          finish-status="success"
          align-center
        >
          <el-step title="选择订单"></el-step>
          <el-step title="核对支付信息"></el-step>
          <el-step title="支付结果信息"></el-step>
        </el-steps>
      </el-card>
      <!-- 二维码页面 -->
      <el-card class="box-card-1" v-if="state == 10">
        <div class="box-card-1-header">
          <div class="box-card-1-header-top">
            <i class="el-icon-success"></i>
            <span class="box-card-1-header-text1">
              <span>订单提交成功,请您尽快付款!</span>
            </span>
            <span class="box-card-1-header-text2">
              <span>订单号: </span>
              <span>{{ orderid }}</span>
            </span>
            <span class="box-card-1-header-text2">
              <span>支付方式: </span>
              <span style="font-weight: 600;">白条支付</span>
            </span>
            <span class="box-card-1-header-text3">
              <span>应付金额: </span>
              <span>{{ totalPrice }}</span>
              <span>元</span>
            </span>
          </div>
        </div>
        <div class="pay-box-box">
          <el-table :data="list.orderItemVO" style="width: 100%">
            <el-table-column prop="productName" label="商品"></el-table-column>
            <el-table-column prop="companyName" label="店铺"></el-table-column>
            <el-table-column prop="productNum" label="数量"></el-table-column>
            <el-table-column prop="unitPrice" label="单价"></el-table-column>
            <el-table-column prop="totalPrice" label="总价"></el-table-column>
          </el-table>
          <div>
            <div>客户信息：{{list.address}}</div>
            <div>
              <el-button type="primary" @click="payBT">立即支付</el-button>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 支付成功页面 -->
      <el-card class="box-card-2" v-if="state == 20">
        <div class="box-card-2-box">
          <span class="el-icon-circle-check"></span>
          <span>您已付款成功！</span>
          <div>
            <span>现在您可以:</span>
          </div>
          <div>
            <el-button
              type="success"
              @click="$router.push('/purchaseOrderList')"
              >查看订单</el-button
            >
            <el-button plain @click="$router.push('/index')"
              >返回首页</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import myHeader from "../components/my-header.vue";
export default {
  components: { myHeader },
  data() {
    return {
      // 订单id
      orderid: "",
      // 订单img
      orderimg: "",
      //商品总价
      totalPrice: "",
      //支付状态
      state: 10,
      list: [],
    };
  },
  methods: {
    // 白条支付
    async payBT(){
        const res=await this.$http({
          method:"post",
          url:`front/order/creditPay`,
          data:{
            orderId:this.orderid,
          }
        })
    },
    //   获取订单信息
    async getpay() {
      this.orderid = JSON.parse(this.$route.query.id);
      // this.orderimg = JSON.parse(this.$route.query.url);
      // console.log(this.$route.query);
      const res = await this.$http({
        method: "get",
        url: `front/order/getOrderByOutTradeNo?outTradeNo=${JSON.parse(
          this.$route.query.id
        )}`,
      });
      this.list = res.data.data;
      console.log(this.list);
      this.totalPrice = res.data.data.totalPrice;
    },
    // 查看支付状态
    async getstate() {
      const res = await this.$http({
        method: "get",
        url: `front/pay/queryOrderPayStatus?orderNo=${JSON.parse(
          this.$route.query.id
        )}`,
        // data: { orderNo: JSON.parse(this.$route.query.id) },
      });
      console.log(res.data.data);
      this.state = res.data.data;
    },
  },
  created() {
    this.getpay();
    setInterval(() => {
      this.getstate();
    }, 1000);
  },
};
</script>

<style>
.box-card-box {
  margin: 0 auto;
  margin-top: 160px;
  width: 1200px;
}
.box-card {
  margin-top: 160px;
  width: 100%;
}
.box-card > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-steps {
  width: 500px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #555;
  line-height: 50px;
}
.box-card-1-header {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background: #f0f9eb;
}
.box-card-1-header-top {
  padding: 5px;
}
.el-icon-success {
  color: #67c23a;
  font-size: 20px;
}
.box-card-1-header-text1 span {
  color: #67c23a;
  padding: 0px 7px;
  font-size: 18px;
}
.box-card-1-header-text2 span:nth-child(1) {
  color: #333;
  margin-left: 15px;
}
.box-card-1-header-text2 span:nth-child(2) {
  color: #e10d0b;
}
.box-card-1-header-text3 span:nth-child(1) {
  color: #333;
  margin-left: 15px;
}
.box-card-1-header-text3 span:nth-child(2) {
  color: #e10d0b;
  font-weight: 600;
}
.box-card-1-header-text3 span:nth-child(3) {
  margin-left: 5px;
}
.pay-box-box {
  width: 100%;
}
/* .pay-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
} */
/* .paybox-center {
  margin: 0 auto;

  width: 260px;
  border: 1px solid #aaa;
} */
/* .pay-box-bottom {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 15px;
} */
/* .pay-box-bottom div:nth-child(1) {
  margin-top: 5px;
  margin-right: 10px;
} */
/* .pay-box-bottom-text {
  margin-top: 20px;
  color: #666;
  margin-bottom: 50px;
} */
/* .iconfont-sao {
  color: #ea753d;
} */
/* .pay-box span:nth-child(2) {
  color: #e10d0b;
  font-weight: 600;
  font-size: 20px;
  padding: 5px;
} */
.box-card-2-box {
  margin: 50px auto;
  width: 60%;
  text-align: center;
}
.box-card-2-box > span:nth-child(1) {
  color: #67c23a;
  font-size: 60px;
}
.box-card-2-box > span:nth-child(2) {
  color: #67c23a;
  font-size: 30px;
  padding: 5px;
}
.box-card-2-box > div:nth-child(3) span:nth-child(1) {
  display: inline-block;
  color: #666;
  margin: 10px;
}
.el-button--success.is-plain {
  color: red;
}
</style>
